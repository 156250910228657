/* global $ */

function init() {
  $('.apartment-list,.favorites__list.list--apartment').magnificPopup({
    delegate: '.apartment-card__segment.segment--media > a[href]',
    type: 'image',
    gallery: {
      enabled: true,
    },
    image: {
      titleSrc: (item) => {
        const el = item.el[0];
        let markup = '';
        if (el.hasAttribute('title')) {
          markup += `<span>${el.getAttribute('title')}</h3>`;
        }
        if (el.dataset.link) {
          markup += `, <a href="${el.dataset.link}">Tutustu asuntoon</a>`;
        }
        return markup;
      },
    },
    mainClass: 'one-off--floorplan-viewer',
  });
}

export default {
  init,
};
