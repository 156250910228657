/* global $ */

function updateButtonText($moduleEl, name) {
  const checkedEls = [...$moduleEl[0].querySelectorAll(`input[name="${name}"]`)].filter(el => el.checked);
  const buttonEl = $moduleEl[0].querySelector(`.form-field__fancy-select-trigger[data-name="${name}"]`);
  const buttonTextEl = buttonEl.querySelector('span:nth-child(2)');

  if (checkedEls && checkedEls.length > 1) {
    buttonTextEl.textContent = `: ${checkedEls.length} valittu`;
  } else if (checkedEls && checkedEls.length === 1) {
    buttonTextEl.textContent = `: ${checkedEls[0].dataset.title}`;
  } else {
    buttonTextEl.textContent = '';
  }
}

function toggleDropdown(name, action) {
  const $selects = $('.form-field__fancy-select');
  const $triggers = $('.form-field__fancy-select-trigger');
  const $dropdowns = $('.form-field__fancy-select-dropdown');

  let state;

  switch (action) {
    case 'show':
      state = true;
      break;
    case 'close':
      state = false;
      break;
    default:
      state = undefined;
  }

  // Close (other/all) dropdowns
  $selects
    .add($triggers)
    .add($dropdowns)
    .not(name ? `[data-name="${name}"]` : '')
    .removeClass('is--open');

  if (name) {
    // Toggle target dropdown
    $selects
      .add($triggers)
      .add($dropdowns)
      .filter(`[data-name="${name}"]`)
      .toggleClass('is--open', state);
  }
}

function handleInput(event, inputEl) {
  const $inputEl = event ? $(event.currentTarget) : $(inputEl);
  const $moduleEl = $inputEl.closest('.apartment-filter');
  const name = $inputEl.closest('.form-field__fancy-select').data('name');

  updateButtonText($moduleEl, name);
}

function init() {
  const moduleEls = document.querySelectorAll('.apartment-filter');
  const $modulesEls = $(moduleEls);

  if (!moduleEls.length) {
    return;
  }

  $(moduleEls).on('click', '.form-field__fancy-select-trigger', (event) => {
    toggleDropdown(event.currentTarget.dataset.name);
  });

  // Close all dropdowns on outside clicks
  $(document).on('click', (event) => {
    if (!event.target.closest('.form-field__fancy-select')) {
      toggleDropdown(false, false);
    }
  });

  $(moduleEls).on('change', 'input[type="checkbox"], input[type="radio"]', handleInput);

  // $(moduleEls).on('submit', 'form', (event) => event.preventDefault());

  $modulesEls.each((index, el) => {
    $(el).find('input[type="checkbox"],input[type="radio"]').each((inputIndex, inputEl) => {
      handleInput(null, inputEl);
    });
  });
}

export default {
  init,
};
