import {
  Swiper,
  Navigation,
  Scrollbar,
  A11y,
} from 'swiper/swiper.esm';

function setImageHeightProp(swiper) {
  const firstCardImageEl = swiper.slides[0].querySelector('.estate-card__image');
  const blockEl = swiper.wrapperEl.closest('.swiper-custom-block');
  blockEl.style.setProperty('--estate-card-image-height', `${firstCardImageEl.offsetHeight}px`);
}

function init() {
  Swiper.use([Navigation, A11y, Scrollbar]);
  const carouselEls = document.querySelectorAll('.b-estate-carousel');
  [...carouselEls].forEach((carouselEl, idx) => {
    const swiper = new Swiper(carouselEl.querySelector('.swiper-container'), {
      // freeMode: true,
      speed: 500,
      scrollbar: {
        el: carouselEl.querySelector('.swiper-scrollbar'),
        draggable: true,
        dragSize: 80,
      },
      // pagination: {
      //   el: carouselEl.querySelector('.swiper-pagination'),
      //   type: 'progressbar',
      //   clickable: true,
      //   // bulletElement: 'button',
      // },
      navigation: {
        nextEl: carouselEl.querySelector('.swiper-button-next'),
        prevEl: carouselEl.querySelector('.swiper-button-prev'),
      },
      a11y: {
        paginationBulletMessage: document.documentElement.lang === 'fi' ? 'Siirry diaan {{index}}' : 'Go to slide {{index}}',
      },
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      // slidesPerView: 'auto',
      slidesPerView: 1,
      breakpoints: {
        560: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          scrollbar: {
            dragSize: 100,
          },
        },
        960: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          scrollbar: {
            dragSize: 160,
          },
        },
        1200: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          scrollbar: {
            dragSize: 320,
          },
        },
      },
      on: {
        init: setImageHeightProp,
        resize: setImageHeightProp,
      },
    });
  });
}

export default {
  init,
};
