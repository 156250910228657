import Headroom from 'headroom.js';

function init() {
  const topBarEl = document.querySelector('.top-bar');
  const heroEl = document.querySelector('.hero');

  if (!topBarEl) {
    return;
  }

  const headroom = new Headroom(topBarEl, {
    offset: heroEl ? heroEl.offsetHeight : 300,
    tolerance: 5,
  });

  headroom.init();
}

export default {
  init,
};
