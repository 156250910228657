/* global $ */

function init() {
  $('.parking-space-list__item-header').on('click', (e) => {
    const $item = $(e.currentTarget).closest('.parking-space-list__item');
    const $pane = $item.find('.parking-space-list__item-details');

    if (window.matchMedia('(max-width: 1199px)').matches) {
      $item.toggleClass('is--open');
      $pane.stop().slideToggle(150);
    }
  });
}

export default {
  init,
};
