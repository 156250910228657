/* global $, document */

class EstateSearchForm {
  constructor(moduleEl) {
    if (!moduleEl) {
      return;
    }

    this.$moduleEl = $(moduleEl);
  }

  mount() {
    this.registerEvents();

    this.$moduleEl.find('input[type="checkbox"]').each((inputIndex, inputEl) => {
      this.handleNestedCheckbox(null, inputEl, true);
    });
  }

  registerEvents() {
    this.$moduleEl.on('click', '.estate-search__select-trigger', (event) => {
      this.toggleDropdown(event.currentTarget.dataset.name);
    });

    // Close all dropdowns on outside clicks
    $(document).on('click', (event) => {
      if (!event.target.closest('.estate-search__select')) {
        this.toggleDropdown(false, false);
      }
    });

    this.$moduleEl.on('click', '.estate-search__accordion-trigger', EstateSearchForm.toggleAccordion);

    this.$moduleEl.on('change', 'input[type="checkbox"]', (event) => {
      this.handleNestedCheckbox(event);
    });
  }

  toggleDropdown(name, action) {
    const $selects = this.$moduleEl.find('.estate-search__select');
    const $triggers = this.$moduleEl.find('.estate-search__select-trigger');
    const $dropdowns = this.$moduleEl.find('.estate-search__dropdown');

    let state;

    switch (action) {
      case 'show':
        state = true;
        break;
      case 'close':
        state = false;
        break;
      default:
        state = undefined;
    }

    // Close (other/all) dropdowns
    $selects
      .add($triggers)
      .add($dropdowns)
      .not(name ? `[data-name="${name}"]` : '')
      .removeClass('is--open');

    if (name) {
      // Toggle target dropdown
      $selects
        .add($triggers)
        .add($dropdowns)
        .filter(`[data-name="${name}"]`)
        .toggleClass('is--open', state);
    }
  }

  static toggleAccordion(event) {
    $(event.currentTarget)
      .toggleClass('is--open')
      .closest('li')
      .toggleClass('is--open')
      .find('ul')
      .toggleClass('is--open');
  }

  handleNestedCheckbox(event, inputEl, isInitialUpdate) {
    const $checkboxEl = event ? $(event.currentTarget) : $(inputEl);
    const $parentCheckboxEl = this.$moduleEl.find(`input[value="${$checkboxEl.data('parent')}"]`);
    const $childCheckboxEls = this.$moduleEl.find(`input[data-parent="${$parentCheckboxEl.length ? $checkboxEl.data('parent') : $checkboxEl.attr('value')}"]`);
    const name = $checkboxEl.closest('.estate-search__select').data('name');
    let $checkedChildrenEls = false;

    if ($parentCheckboxEl.length) {
      // Clicked (or just updating) a child checkbox

      // Find checked child checkboxes
      $checkedChildrenEls = $childCheckboxEls.filter((index, el) => el.checked);

      if (!$checkedChildrenEls.length) {
        // No checked child checkboxes, uncheck parent
        $parentCheckboxEl.prop('checked', false);
        $parentCheckboxEl.prop('indeterminate', false);
      } else if ($checkedChildrenEls.length === $childCheckboxEls.length) {
        // All child checkboxes checked, check parent
        $parentCheckboxEl.prop('checked', true);
        $parentCheckboxEl.prop('indeterminate', false);
      } else {
        // Some child checkboxes checked, set parent indeterminate
        $parentCheckboxEl.prop('checked', false);
        $parentCheckboxEl.prop('indeterminate', true);
      }

    // } else if (event.originalEvent.isTrusted) {
    } else if (!isInitialUpdate) {
      // Clicked (or just updating) a parent checkbox

      // Parent and children will have same state, can't be "indeterminate"
      $checkboxEl.prop('indeterminate', false);

      // Set child checkboxes to same state as parent
      $childCheckboxEls.each((index, el) => {
        $(el).prop('checked', $checkboxEl.prop('checked'));
      });
    }

    this.updateButtonText(name);
  }

  updateButtonText(name) {
    const checkboxEls = [...this.$moduleEl[0].querySelectorAll(`input[name="${name}"]`)];
    const parentCheckboxEls = checkboxEls.filter(el => !el.matches('[data-parent]'));
    const childCheckboxEls = checkboxEls.filter(el => el.matches('[data-parent]'));
    const checkableEls = (childCheckboxEls.length ? childCheckboxEls : parentCheckboxEls);
    const checkedEls = checkableEls.filter(el => el.checked);
    const buttonEl = this.$moduleEl[0].querySelector(`.estate-search__select-trigger[data-name="${name}"]`);
    const buttonTextEl = buttonEl.querySelector('span:nth-child(2)');

    if (checkedEls.length > 1) {
      buttonTextEl.textContent = `: ${checkedEls.length} valittu`;
    } else if (checkedEls.length === 1) {
      buttonTextEl.textContent = `: ${checkedEls[0].dataset.title}`;
    } else {
      buttonTextEl.textContent = '';
    }
  }
}


function init() {
  [...document.querySelectorAll('.estate-search')].forEach((el) => {
    new EstateSearchForm(el).mount();
  });
}

export default {
  init,
};
