/* global $ */

function init() {
  $('input#input_2_1').on('focus', (event) => {
    $(event.target).closest('form').addClass('expanded');
  });
}

export default {
  init,
};
