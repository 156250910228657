import SimpleBar from 'simplebar';

function init() {
  const navEl = document.querySelector('.nav-desktop');

  if (!navEl) {
    return;
  }

  const navScrollerEl = navEl.querySelector('.nav-scroller');

  // Initialize Simplebar -scroll
  const simpleBar = new SimpleBar(navScrollerEl, {
    autoHide: false,
  });
  const simpleBarScrollEl = simpleBar.getScrollElement();

  const currentItemEl = navEl.querySelector('.current-menu-item');
  const currentItemOutOfView = currentItemEl
                              && currentItemEl.offsetLeft > (
                                simpleBarScrollEl.offsetWidth - currentItemEl.offsetWidth
                              );

  if (currentItemOutOfView) {
    // Scroll current item to center
    simpleBarScrollEl.scrollLeft = currentItemEl.offsetLeft
                              + (currentItemEl.offsetWidth / 2)
                              - (simpleBarScrollEl.offsetWidth / 2);
  }
}

export default {
  init,
};
