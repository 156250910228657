/* eslint no-param-reassign: ["error", { "props": false }] */
/* global $ */

function updateButtonText(name) {
  const checkedEls = [...document.querySelectorAll(`input[name="${name}"]`)].filter(el => el.checked);
  const buttonEl = document.querySelector(`.apartment-search__select-trigger[data-name="${name}"]`);
  const buttonTextEl = buttonEl.querySelector('span:nth-child(2)');

  if (checkedEls.length > 1) {
    buttonTextEl.textContent = `: ${checkedEls.length} valittu`;
  } else if (checkedEls.length === 1) {
    buttonTextEl.textContent = `: ${checkedEls[0].dataset.title}`;
  } else {
    buttonTextEl.textContent = '';
  }
}

function toggleDropdown(name, action) {
  const $selects = $('.apartment-search__select');
  const $triggers = $('.apartment-search__select-trigger');
  const $dropdowns = $('.apartment-search__dropdown');

  let state;

  switch (action) {
    case 'show':
      state = true;
      break;
    case 'close':
      state = false;
      break;
    default:
      state = undefined;
  }

  // Close (other/all) dropdowns
  $selects
    .add($triggers)
    .add($dropdowns)
    .not(name ? `[data-name="${name}"]` : '')
    .removeClass('is--open');

  if (name) {
    // Toggle target dropdown
    $selects
      .add($triggers)
      .add($dropdowns)
      .filter(`[data-name="${name}"]`)
      .toggleClass('is--open', state);
  }
}

function toggleAccordion(event) {
  $(event.currentTarget)
    .toggleClass('is--open')
    .closest('li')
    .toggleClass('is--open')
    .find('ul')
    .toggleClass('is--open');
}

function handleNestedCheckbox(event) {
  const checkboxEl = event.currentTarget;
  const moduleEl = checkboxEl.closest('.apartment-search');
  const parentCheckboxEl = moduleEl.querySelector(`input[value="${checkboxEl.dataset.parent}"]`);
  const childCheckboxEls = moduleEl.querySelectorAll(`input[data-parent="${parentCheckboxEl ? checkboxEl.dataset.parent : checkboxEl.value}"]`);
  const checkboxName = checkboxEl.closest('.apartment-search__select').dataset.name;
  let checkedChildrenEls = false;

  if (parentCheckboxEl) {
    // Clicked child checkbox

    checkedChildrenEls = [...childCheckboxEls].filter(el => el.checked);

    parentCheckboxEl.indeterminate = false;

    if (!checkedChildrenEls.length) {
      parentCheckboxEl.checked = false;
    } else if (checkedChildrenEls.length === childCheckboxEls.length) {
      parentCheckboxEl.checked = true;
    } else {
      parentCheckboxEl.checked = false;
      parentCheckboxEl.indeterminate = true;
    }
  } else if (event.originalEvent.isTrusted) {
    // Clicked parent checkbox

    checkboxEl.indeterminate = false;

    [...childCheckboxEls].forEach((el) => {
      el.checked = checkboxEl.checked;
    });
  }

  updateButtonText(checkboxName);
}

function init() {
  const moduleEls = document.querySelectorAll('.apartment-search');

  if (!moduleEls.length) {
    return;
  }

  $(moduleEls).on('click', '.apartment-search__select-trigger', (event) => {
    toggleDropdown(event.currentTarget.dataset.name);
  });

  // Close all dropdowns on outside clicks
  $(document).on('click', (event) => {
    if (!event.target.closest('.apartment-search__select')) {
      toggleDropdown(false, false);
    }
  });

  $(moduleEls).on('click', '.apartment-search__accordion-trigger', toggleAccordion);

  $(moduleEls).on('change', 'input[type="checkbox"]', handleNestedCheckbox);

  // $(moduleEls).on('submit', 'form', (event) => event.preventDefault());

  [...document.querySelector('.apartment-search__select-trigger')].forEach((el) => {
    updateButtonText(el.dataset.name);
  });
}

export default {
  init,
};
