/* global jQuery */

function init() {
  const galleryEl = document.querySelector('.photo-gallery');

  if (!galleryEl) {
    return;
  }

  // https://github.com/dimsemenov/Magnific-Popup/issues/9
  // https://github.com/dimsemenov/Magnific-Popup/issues/626
  // https://github.com/dimsemenov/Magnific-Popup/issues/125
  jQuery('.photo-gallery__items a').magnificPopup({
    gallery: { enabled: true },
    callbacks: {
      elementParse: (item) => {
        const extension = item.src.split('.').pop();
        switch (extension) {
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'gif':
          case 'webp':
            item.type = 'image';
            break;
          case 'mp4':
            item.type = 'inline';
            item.src = item.el[0].nextElementSibling.firstElementChild.outerHTML;
            break;
          case 'html':
            item.type = 'ajax';
            break;
          default:
            item.type = 'iframe';
        }
      },
      open: function() {
        const $video = jQuery(this.content).find('video');
        if ($video.length) {
          // Play video on open:
          $video[0].play();
        }
      },
      close: function() {
        const $video = jQuery(this.content).find('video');
        if ($video.length) {
          // Reset video on close:
          $video[0].load();
        }
      }
    }
  });
}

export default {
  init,
};
