function init() {
  const bottomBarEl = document.querySelector('.c-bottom-bar');
  const newsletterFormEl = document.querySelector('#tilaa-uutiskirje');

  if (!bottomBarEl || !newsletterFormEl) {
    return;
  }

  const observerOptions = {
    rootMargin: '0px',
    threshold: 0,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      bottomBarEl.classList.toggle('is-pinned', entry.intersectionRatio <= 0);
    });
  }, observerOptions);

  observer.observe(newsletterFormEl);
}

export default {
  init,
};
