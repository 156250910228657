/* eslint-env browser */
/* global $ */

import googleMapsApiLoader from 'google-maps-api-loader';

let mapEl;
let mapCanvasEl;
let stylesArray = [];

let estateId;
let map;
// let estateSlug;
const markers = {};
let placeMarkers = [];
let googleApi;
let infowindow;
let isLoaded = false;
let inView = false;

// @link https://jamesbedont.com/2016/03/23/GoogleMapsApiIntroduction.html
function geoCodeAddress(geocoder, address) {
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        resolve(results);
      } else {
        reject(status);
      }
    });
  });
}

function createPlaceMarker(place) {
  const imagesPath = `${document.location.origin}/wp-content/themes/rakennusteho/src/assets/images`;

  const icons = {
    bus_station: `${imagesPath}/map-bus.png`,
    // daycare: `${imagesPath}/map-daycare.png`,
    light_rail_station: `${imagesPath}/map-light-rail.png`,
    school: `${imagesPath}/map-daycare.png`,
    store: `${imagesPath}/map-store.png`,
    subway_station: `${imagesPath}/map-subway.png`,
  };

  const image = {
    url: icons[place.primaryType],
    size: new googleApi.maps.Size(16, 16),
    origin: new googleApi.maps.Point(0, 0),
    anchor: new googleApi.maps.Point(8, 8),
    scaledSize: new googleApi.maps.Size(16, 16),
  };


  const marker = new googleApi.maps.Marker({
    position: place.location,
    icon: image,
    title: place.name,
    map,
  });

  function infoWindowHandler() {
    infowindow.setContent(place.name);
    infowindow.open(map, this);
  }

  marker.primaryType = place.primaryType;

  googleApi.maps.event.addListener(marker, 'click', infoWindowHandler);

  return marker;
}

function loadMap() {
  const $controls = $('.estate-map__controls');
  const $controlInputs = $controls.find('input[type="checkbox"]');

  try {
    stylesArray = JSON.parse(document.getElementById('estate-map__style-data').textContent);
  } catch (error) {
    // console.err(error);
  }

  // estateSlug = mapCanvasEl.dataset.slug;
  estateId = mapCanvasEl.dataset.id;

  // TODO: test if this can be changed to `click`. For a11y reasons.
  $('.estate-map__controls-toggle').on('touchstart', () => {
    $controls.toggleClass('is--open');
  });

  $controls.on('mouseenter', () => {
    $controls.addClass('is--open');
  });

  $controls.on('mouseleave', () => {
    $controls.removeClass('is--open');
  });

  $controlInputs.on('change', () => {
    const activeTypes = [];

    $controlInputs.each((index, inputEl) => {
      if (inputEl.checked) {
        activeTypes.push(inputEl.value);
      }
    });

    placeMarkers.forEach((marker) => {
      if (activeTypes.indexOf(marker.primaryType) > -1) {
        marker.setMap(map);
      } else {
        marker.setMap(null);
      }
    });
  });

  $controls.on('click', 'label', () => {
    $controls.addClass('is--open');
  });

  // Get Google API
  googleMapsApiLoader({
    libraries: [],
    apiKey: 'AIzaSyBQjfVVQ-b8hQDKgbt5kVT9vnbOtOIqkWg', // dev@tyomaa.com, Rakennusteho, API Key 1 (client)
  })
    .then((api) => {
      googleApi = api;
      const geocoder = new googleApi.maps.Geocoder();

      let coords;

      // Get coordinates from DOM
      const lat = parseFloat(mapCanvasEl.dataset.latitude);
      const lng = parseFloat(mapCanvasEl.dataset.longitude);

      if (lat && lng) {
        coords = { lat, lng };
      } else {
      // No coordinates in DOM, get by address
        coords = geoCodeAddress(geocoder, mapCanvasEl.dataset.address)
          .then(results => ({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          }));
      }

      return coords;
    })
    .then((coords) => {
    // Create map

      map = new googleApi.maps.Map(mapCanvasEl, {
        center: { lat: coords.lat, lng: coords.lng },
        zoom: 15,
        zoomControlOptions: {
          position: googleApi.maps.ControlPosition.LEFT_CENTER,
        },
        streetViewControlOptions: {
          position: googleApi.maps.ControlPosition.LEFT_CENTER,
        },
        fullscreenControlOptions: {
          position: googleApi.maps.ControlPosition.LEFT_CENTER,
        },
      });

      infowindow = new googleApi.maps.InfoWindow();

      if (stylesArray.length) {
        const styledMapType = new googleApi.maps.StyledMapType(stylesArray, { name: 'ultra-light-with-labels' });

        // Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set('rt_map', styledMapType);
        map.setMapTypeId('rt_map');
      }

      // Create estate location marker
      markers.rtLocation = new googleApi.maps.Marker({
        position: { lat: coords.lat, lng: coords.lng },
        title: mapCanvasEl.dataset.name,
        icon: {
          url: `${window.location.origin}/wp-content/themes/rakennusteho/src/assets/images/google-map-marker.png`,
          labelOrigin: new googleApi.maps.Point(25, 90),
        },
        label: {
          text: mapCanvasEl.dataset.name,
          color: '#f26c4f',
          fontSize: '16px',
          fontWeight: 'bold',
        },
        map,
      });

      mapEl.classList.remove('is--loading');
    })
    // Get nearby estate places from REST API
    .then(() => window.fetch(`/wp-json/rt/v1/estate-map-places/${estateId}`))
    .then(response => response.json())
    .then((estateMapData) => {
    // Convert place-objects to simpler versions, for Markers to use
      const placeEntries = Object.entries(estateMapData.places);
      const plainPlaces = placeEntries.map(([type, places]) => (
        places.map(place => ({
          primaryType: type,
          name: place.name,
          location: {
            lat: place.geometry.location.lat,
            lng: place.geometry.location.lng,
          },
        }))
      ));

      // Flatten array of arrays
      const plainPlacesFlat = plainPlaces
        .reduce((accumulator, current) => accumulator.concat(current));

      // Create place markers
      placeMarkers = plainPlacesFlat.map(plainPlace => createPlaceMarker(plainPlace));

      mapEl.classList.remove('is--loading-places');
    }, () => {
    // console.error(error);
    });
}

function maybeLoad() {
  const hasCookieConsent = window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.statistics;
  if (!isLoaded && inView && hasCookieConsent) {
    isLoaded = true;
    loadMap();
  }
}

function init() {
  mapEl = document.querySelector('.estate-map');
  mapCanvasEl = document.getElementById('estate-map__canvas');

  if (!mapCanvasEl) {
    return;
  }

  const observerOptions = {
    rootMargin: '0px',
    threshold: 0,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      inView = entry.intersectionRatio > 0;
      maybeLoad();
    });
  }, observerOptions);

  observer.observe(mapCanvasEl);

  // Initialize also if consent given afterwards
  window.addEventListener('CookiebotOnAccept', () => {
    maybeLoad();
  });
}

export default {
  init,
};
