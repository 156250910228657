function setView(view = 'table') {
  const apartmentListEl = document.querySelector('.apartment-list');
  const apartmentListConstrainEl = apartmentListEl.querySelector('.js-apartment-list-items-constrain');
  const apartmentCardEls = apartmentListEl.querySelectorAll('.apartment-card');

  if (view === 'grid') {
    apartmentListEl.classList.remove('apartment-list--table');
    apartmentListEl.classList.add('apartment-list--grid');
    apartmentListConstrainEl.classList.remove('constrain--narrowish');
    apartmentListConstrainEl.classList.remove('constrain--flush');
    apartmentListConstrainEl.classList.add('constrain--default');
    apartmentCardEls.forEach((el) => {
      el.classList.remove('apartment-card--row');
      el.classList.add('apartment-card--stack');
    });
  } else {
    apartmentListEl.classList.remove('apartment-list--grid');
    apartmentListEl.classList.add('apartment-list--table');
    apartmentListConstrainEl.classList.add('constrain--narrowish');
    apartmentListConstrainEl.classList.add('constrain--flush');
    apartmentListConstrainEl.classList.remove('constrain--default');
    apartmentCardEls.forEach((el) => {
      el.classList.remove('apartment-card--stack');
      el.classList.add('apartment-card--row');
    });
  }
}

function init() {
  const viewControlsEl = document.querySelector('.apartment-list__view-controls');
  const initialValue = window.localStorage.getItem('apartment-list-view') || 'table';

  if (!viewControlsEl) {
    return;
  }

  [...viewControlsEl.querySelectorAll('input')].forEach((el) => {
    el.addEventListener('change', () => {
      if (el.checked) {
        setView(el.value);
        window.localStorage.setItem('apartment-list-view', el.value);
      }
    });
    if (el.value === initialValue) {
      el.checked = true;
      setView(el.value);
    }
  });
}

export default {
  init,
};
