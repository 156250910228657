import {
  Swiper,
  Navigation,
  Scrollbar,
  A11y,
} from 'swiper/swiper.esm';

// import { getLazyloadObserver } from '../lazy-load/lazy-load';

// function loadNextSlideImages(swiper) {
//   const nextSlideEl = swiper.slides[swiper.activeIndex + 1];
//   const nextSlideImageEls = nextSlideEl ? nextSlideEl.querySelectorAll('.lazy-load') : [];
//   nextSlideImageEls.forEach((el) => {
//     getLazyloadObserver().triggerLoad(el);
//   });
// }

// function setImageHeightProp(swiper) {
//   const firstSlideImageEl = swiper.slides[0].querySelector('img');
//   const blockEl = swiper.wrapperEl.closest('.swiper-custom-block');
//   blockEl.style.setProperty('--slide-image-height', `${firstSlideImageEl.offsetHeight}px`);
// }

function init() {
  Swiper.use([Navigation, A11y, Scrollbar]);
  const carouselEls = document.querySelectorAll('.b-photo-carousel');
  [...carouselEls].forEach((carouselEl, idx) => {
    const swiper = new Swiper(carouselEl.querySelector('.swiper-container'), {
      // freeMode: true,
      speed: 500,
      scrollbar: {
        el: carouselEl.querySelector('.swiper-scrollbar'),
        draggable: true,
        // dragSize: 80,
      },
      // pagination: {
      //   el: carouselEl.querySelector('.swiper-pagination'),
      //   type: 'progressbar',
      //   clickable: true,
      //   // bulletElement: 'button',
      // },
      navigation: {
        nextEl: carouselEl.querySelector('.swiper-button-next'),
        prevEl: carouselEl.querySelector('.swiper-button-prev'),
      },
      a11y: {
        paginationBulletMessage: document.documentElement.lang === 'fi' ? 'Siirry diaan {{index}}' : 'Go to slide {{index}}',
      },
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slidesPerView: 1,
      // on: {
      //   init: loadNextSlideImages,
      //   slideChange: loadNextSlideImages,
      // },

      // on: {
      //   init: setImageHeightProp,
      //   resize: setImageHeightProp,
      // },
    });
  });
}

export default {
  init,
};
