/* global $ */

function init() {

  $('a[href^="https://zoan.toimitilat.pro/"]').magnificPopup({
    type: 'iframe',
    mainClass: 'one-off--vr-viewer',
  });

  $('a[href^="https://tours.zoandev.com"]').magnificPopup({
    type: 'iframe',
    mainClass: 'one-off--vr-viewer',
  });

  $('a[href^="https://my.matterport.com/"]').magnificPopup({
    type: 'iframe',
    mainClass: 'one-off--vr-viewer',
  });

  $('a[href^="https://www.vd.fi/"]').magnificPopup({
    type: 'iframe',
    mainClass: 'one-off--vr-viewer',
  });

  $('a[href^="https://tour.zre.fi/"]').magnificPopup({
    type: 'iframe',
    mainClass: 'one-off--vr-viewer',
  });

  $('a[href*="/panoraama"][href*="image="]').magnificPopup({
    type: 'iframe',
    mainClass: 'one-off--vr-viewer',
  });
}

export default {
  init,
};
