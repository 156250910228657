/* global $ */

import * as lazyLoad from '../lazy-load/lazy-load';
import apartmentCard from '../apartment-card/apartment-card';

function getCart() {
  // const cartJSON = window.localStorage.getItem(`favorites_${type}`);
  const cartJSON = window.localStorage.getItem('favorites');
  const cart = cartJSON ? JSON.parse(cartJSON) : [];
  return cart;
}

function setCart(type, cart) {
  // window.localStorage.setItem(`favorites_${type}`, JSON.stringify(cart));
  window.localStorage.setItem('favorites', JSON.stringify(cart));
  return cart;
}

function removeItem(type, id) {
  const cart = getCart(type);
  const itemIndex = cart.findIndex(item => (item.id === id));
  if (itemIndex > -1) {
    cart.splice(itemIndex, 1);
  }
  return setCart(type, cart);
}

function addItem(type, id) {
  const cart = removeItem(type, id);
  const item = {
    id,
    type,
  };
  cart.push(item);
  return setCart(type, cart);
}

export function updateUi() {
  const buttonEls = document.querySelectorAll('.favorites__trigger');
  const cart = getCart();
  const cartCountEl = document.querySelector('.top-bar__control.control--favorites');

  if (cartCountEl) cartCountEl.querySelector('.label span').textContent = cart.length;

  [...buttonEls].forEach((buttonEl) => {
    // Can't use `.find()` because automated tests don't know enough ES6 :(
    // const activeItem = cart.find((item) => item.id === Number(buttonEl.dataset.id));
    // TODO: use Babel to convert some ES6 array functions
    let i = 0;
    let isActive = false;

    for (i; i < cart.length; i += 1) {
      const item = cart[i];
      if (item.id === Number(buttonEl.dataset.id)) {
        isActive = true;
        break;
      }
    }

    buttonEl.classList.toggle('is--active', isActive);
  });
}


function init() {
  document.addEventListener('click', (event) => {
    const buttonEl = event.target.classList.contains('favorites__trigger') ? event.target : null;

    if (buttonEl) {
      if (buttonEl.classList.contains('is--active')) {
        removeItem(event.target.dataset.type, Number(event.target.dataset.id));
      } else {
        const cardEl = buttonEl.closest('.estate-card') || buttonEl.closest('.apartment-card');

        addItem(buttonEl.dataset.type, Number(buttonEl.dataset.id), cardEl);
      }

      updateUi();
    }
  });

  const $lists = $('.favorites__list');
  const cart = getCart();

  if ($lists.length) {
    const requests = cart.map((item) => window.fetch(`/wp-json/rt/v1/favorite/${item.id}`).then((res) => res.json()));
    Promise.all(requests)
      .then((responses) => {
        responses.forEach((data) => {
          const $card = $(data.cardHtml).removeClass('.card--featured').addClass('hidden');
          const $listItem = $card.wrap('<div class="favorites__list-item"></div>').parent();
          $lists.filter(`.list--${data.type}`).append($listItem);
        });
      })
      .then(() => {
        updateUi();
        apartmentCard.init();
        lazyLoad.refresh();
        $lists.siblings('.icon').remove();
        $lists.removeClass('is--loading');
        $lists.find('.estate-card, .apartment-card').removeClass('hidden');
      });
  }

  window.setTimeout(() => {
    updateUi();
  }, 100);
}

export default {
  init,
};
