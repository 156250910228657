/* global $ */

function init() {
  const el = document.querySelector('.apartment-single');

  if (!el) {
    return;
  }

  $('.apartment-single__floorplan').magnificPopup({
    type: 'image',
    gallery: {
      enabled: true,
    },
  });
}

export default {
  init,
};
