/* global $ */

function init() {
  $('.gform_wrapper').on('click', '.gfield.privacy-policy a', (event) => {
    event.stopPropagation();
  });
}

export default {
  init,
};
