/* global $ */

import { createFocusTrap } from 'focus-trap';

const showEls = document.querySelectorAll('[data-action="megamenu-show"]');
const closeEls = document.querySelectorAll('[data-action="megamenu-close"]');
const toggleEls = document.querySelectorAll('[data-action="megamenu-toggle"]');
const navEl = document.querySelector('.megamenu');
const backdropEl = document.querySelector('.megamenu__backdrop');
let focusTrap;

const state = {
  open: false,
  tab: '',
};

function show(event) {
  const tab = event ? event.currentTarget.dataset.megamenuTab : '';

  event.preventDefault();

  state.open = true;

  // [...toggleEls].forEach((el) => { el.classList.add('is--open'); });

  backdropEl.classList.add('is--open');
  navEl.classList.add('is--open');
  document.body.classList.add('megamenu--open');


  if (tab) {
    state.tab = tab;
    [...document.querySelectorAll('[data-megamenu-tab]')].forEach((el) => {
      el.classList.toggle('is--active', (el.dataset.megamenuTab === state.tab));
    });
  }

  focusTrap.activate();

}

function close(event) {
  if (event) {
    event.preventDefault();
  }

  state.open = false;

  // [...toggleEls].forEach((el) => { el.classList.remove('is--open'); });

  focusTrap.deactivate();

  [...document.querySelectorAll('[data-megamenu-tab]')].forEach((el) => {
    el.classList.remove('is--active');
  });

  backdropEl.classList.remove('is--open');
  navEl.classList.remove('is--open');

  document.body.classList.remove('megamenu--open');
}

function toggle(event) {
  const tab = event ? event.currentTarget.dataset.megamenuTab : '';

  event.preventDefault();

  if (state.open && state.tab === tab) {
    close(event);
  } else {
    show(event);
  }
}

function registerEvents() {
  if (showEls) [...showEls].forEach((el) => { el.addEventListener('click', show); });
  if (closeEls) [...closeEls].forEach((el) => { el.addEventListener('click', close); });
  if (toggleEls) [...toggleEls].forEach((el) => { el.addEventListener('click', toggle); });
  if (backdropEl) backdropEl.addEventListener('click', close);

  // window.matchMedia('(min-width: 960px)').addListener(handleBreakpoint);

  $(document).on('keyup.sidebar', (e) => {
    if (e.keyCode === 27) {
      close();
    }
  });

  $(navEl).on('click', 'li.level-1 > div > button', (event) => {
    $(event.currentTarget).toggleClass('is--open')
      .closest('li')
      .toggleClass('is--open')
      .find('ul.level-2')
      .toggleClass('is--open');
  });
}

// function handleBreakpoint(mql) {
//   if (mql.matches) {
//     close();
//   }
// }

function init() {
  if (navEl && toggleEls) {
    registerEvents();
    focusTrap = createFocusTrap(navEl, {
      clickOutsideDeactivates: true,
    });
  }
}

export default {
  init,
};
