import smoothScrollPolyfill from 'smoothscroll-polyfill';
import objectFitImages from 'object-fit-images';
import focusVisiblePolyfill from 'focus-visible';
import focusWithinPolyfill from 'focus-within';
import 'alpinejs';

import * as components from '../../components/index';

function start() {
  // Polyfills
  smoothScrollPolyfill.polyfill();
  objectFitImages(); // this may require window.objectFitImages()
  focusWithinPolyfill(document);
  // window.objectFitImages();

  // Components
  components.navMobile.init();
  components.estateMap.init();
  components.topBar.init();
  components.apartmentList.init();
  components.apartmentCard.init();
  components.apartmentSingle.init();
  components.apartmentSearch.init();
  components.apartmentFilter.init();
  // components.jobsList.init();
  components.favorites.init();
  components.parkingSpaceList.init();
  components.estateSearch.init();
  components.photoGallery.init();
  components.likeMachine.init();
  components.formSubscribe.init();
  components.gravityForms.init();
  components.lazyLoad.init();
  components.navDesktop.init();
  components.estateLander.init();
  // components.search.init();
  components.estateCarousel.init();
  components.photoCarousel.init();
  components.bottomBar.init();
}

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', start);
} else {
  start();
}
