/* eslint no-param-reassign: ["error", { "props": false }] */
/* global $ */

import { createFocusTrap } from 'focus-trap';

import * as favorites from '../favorites/favorites';
import * as lazyLoad from '../lazy-load/lazy-load';

const state = {};
let $likeMachineBillboard;
let $likeMachineShow;
let $likeMachine;
let estateFeatures = '';
let focusTrap;

function closeModal() {
  $likeMachine.removeClass('is--open');
  $likeMachineBillboard.removeClass('is--loading');
  $(document).off('keyup.likeMachine');

  window.setTimeout(() => {
    focusTrap.deactivate();
    $likeMachine.remove();
    document.body.style.overflow = '';
    document.body.style.height = '';
  }, 600);
}

function handleResults() {
  $likeMachine.find('.estate-card a').each((index, el) => {
    el.target = '_blank';
  });

  favorites.updateUi();

  // Calculate score for each estate
  estateFeatures.forEach((estate) => {
    const combinedScore = estate.features.reduce((combinedValue, currentValue) => {
      const feature = currentValue[0];
      const score = state.values.indexOf(feature) > -1 ? currentValue[1] : 0;
      return combinedValue + score;
    }, 0);

    estate.score = combinedScore;
  });

  // Filter out estates with no available apartments
  const estateFeaturesNoEmpty = estateFeatures.filter((estate) => {
    const apartmentsFound = $likeMachine.find(`[data-slug="${estate.polkutunnus}"]`).length;
    return apartmentsFound;
  });

  // Sort estates by score
  const estatesSorted = estateFeaturesNoEmpty.reverse().sort((a, b) => {
    const keyA = a.score;
    const keyB = b.score;

    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });

  // Set estate-card DOM order, show only top 3
  estatesSorted.slice(0, 3).forEach((estate, index) => {
    const $estateCard = $likeMachine.find(`[data-slug="${estate.polkutunnus}"]`);
    $estateCard.closest('.estate-list__item').addClass('like-machine__suggested-item').css('order', index);
  });
}

function initMachine() {
  $likeMachine = $('.like-machine');

  const $pagination = $likeMachine.find('.like-machine__pagination');
  const $closeButton = $('.like-machine__close');
  const $firstSlide = $likeMachine.find('.like-machine__slide').first();

  const estateFeaturesDataEl = document.getElementById('like-machine__data');

  try {
    estateFeatures = estateFeaturesDataEl ? JSON.parse(estateFeaturesDataEl.textContent) : '';
  } catch (error) {
    // console.error(error);
  }

  state.values = [];

  $firstSlide.addClass('is--active');
  $firstSlide.find('input').prop('disabled', false);

  $likeMachine.on('change', 'input', (event) => {
    const $input = $(event.target);
    const $slide = $input.closest('.like-machine__slide');
    const $slideNext = $slide.next();
    const feature = $input.val();

    // Save feature to state
    state.values.push(feature);

    // Safeguard, avoid double events
    $slide.find('input').prop('disabled', true);


    // Handle slide change, transitions etc.
    // TODO: Don't use timeouts for queuing animations...

    $slide.addClass('animate--out');

    window.setTimeout(() => {
      $slide.removeClass('is--active');
      $slideNext.addClass('is--active');

      $slideNext.find('input').prop('disabled', false);

      $pagination.find('span.is--active').removeClass('is--active').next().addClass('is--active');

      // Results slide (last)
      if ($slideNext.hasClass('slide--results')) {
        $closeButton.removeClass('button--white-orange').addClass('button--default-inverse ');
        $pagination.addClass('is--hidden');

        handleResults();
      }
    }, 600);
  });
}

function showModal() {
  const $closeButton = $('.like-machine__close');

  $likeMachine.addClass('is--open');

  window.setTimeout(() => {
    initMachine();
    lazyLoad.refresh();
    focusTrap = createFocusTrap($likeMachine[0], {
      escapeDeactivates: false,
      initialFocus: '.like-machine input[type="checkbox"]',
    });
    focusTrap.activate();
  }, 600);

  $closeButton.on('click', (event) => {
    event.preventDefault();
    closeModal();
  });

  document.body.style.overflow = 'hidden';
  document.body.style.height = '100%';

  $(document).on('keyup.likeMachine', (event) => {
    // Close on esc
    if (event.keyCode === 27) {
      closeModal();
    }
  });
}


function init() {
  $likeMachine = $('.like-machine');
  $likeMachineShow = $('[data-action="like-machine-show"]');
  $likeMachineBillboard = $likeMachineShow.closest('.page-segment').addClass('like-machine-billboard');

  if ($likeMachine.length) {
    initMachine();
  }

  $likeMachineShow.on('click', (event) => {
    event.preventDefault();

    $likeMachineBillboard.addClass('is--loading');

    $.get({
      url: $likeMachineShow.attr('href'),
      data: {
        ajax: true,
      },
    }).done((data) => {
      $(document.body).append(data);
      window.setTimeout(() => {
        $likeMachine = $('.like-machine');
        showModal();
      }, 100);
    });
  });
}

export default {
  init,
};
